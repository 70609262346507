import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { graphql } from 'gatsby';
import Layout from "../components/layout";

const ContactPage = ({
  data: {
    site
  },
}) => {

  // Define your custom JavaScript function
  const initContactForm = () => {
    const form = document.querySelector("[data-form]");
    const formInputs = document.querySelectorAll("[data-form-input]");
    const formBtn = document.querySelector("[data-form-btn]");
    const msg = document.getElementById("msg");

    for (let i = 0; i < formInputs.length; i++) {
      formInputs[i].addEventListener("input", function () {
        if (form.checkValidity()) {
          formBtn.removeAttribute("disabled");
        } else {
          formBtn.setAttribute("disabled", "");
        }
      });
    }

    form.addEventListener("submit", formSubmit);

    function formSubmit(e) {
      e.preventDefault();
      const formData = new FormData(e.target);
      const formBtn = document.querySelector("[data-form-btn]");
      fetch("https://getform.io/f/4c39611d-b928-4403-9c40-f5b4dca3ce0c", {
        method: "POST",
        body: formData,
        headers: {
          "Accept": "application/json",
        },
      }).then(response => {
        if (!response.ok) {
          throw new Error(`An error occurred: ${response.statusText}`);
        } else {
          msg.style.display = "flex";
          e.target.reset();
          formBtn.setAttribute("disabled", "true");
          setTimeout(() => {
            msg.style.display = "none";
          }, 7000);
        }
      }).catch(error => {
        console.log(error);
      });
    }
  };

  // Use useEffect to run your custom JavaScript function when the component mounts
  useEffect(() => {
    initContactForm();
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Contact — {site.siteMetadata.title}</title>
        <meta name="description" content={"Contact page of " + site.siteMetadata.description} />
      </Helmet>
      <header>
        <h2 className="h2 article-title">Contact</h2>
      </header>
      <section className="mapbox" data-mapbox="">
        <figure>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4414.61299221942!2d89.35614107600652!3d24.653972553487787!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39fc4d32352b8987%3A0xc99922c102429db9!2shalimjr.!5e1!3m2!1sen!2sbd!4v1694148428462!5m2!1sen!2sbd" width="400" height="300" loading="lazy"></iframe>
        </figure>
      </section>
      <section className="contact-form">
        <h3 className="h3 form-title">Contact Form</h3>
        <form action="#" className="form" data-form="" method="POST" id="form">
          <div className="input-wrapper">
            <input type="text" name="fullname" className="form-input" placeholder="Full name" required="" data-form-input="" autoComplete="off" />
            <input type="email" name="email" className="form-input" placeholder="Email address" required="" data-form-input="" />
            <input type="hidden" name="_gotcha" />
          </div>
          <textarea name="message" className="form-input" placeholder="Your Message" required="" data-form-input="" spellCheck="false"></textarea>
          <div id="msg" className="about-text">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path className="fa-primary" d="M369 175c9.4 9.4 9.4 24.6 0 33.9L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0z" />
              <path className="fa-secondary" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
            </svg> Thank you for your kind response 🙂. Will get back to you.
          </div>
          <button className="btn" type="submit" disabled="true" data-form-btn="">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path className="fa-primary" d="M380.7 185.8c5.1-6.7 4.2-16.2-2.1-21.8s-15.9-5.3-21.9 .7l-179 179-13 13c-3 3-4.7 7.1-4.7 11.3v8 56 48c0 13.2 8.1 25 20.3 29.8s26.2 1.6 35.2-8.1L284 427.7l-60-25V389.4L380.7 185.8z" />
              <path className="fa-secondary" d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L224 402.7V389.4L380.7 185.8c5.2-6.7 4.2-16.4-2.3-21.9s-16.1-5.1-22 1.1L178.8 350.6l-14.1 14.1c-3 3-4.7 7.1-4.7 11.3l-28.3-11.8-112-46.7C8.4 312.8 .8 302.2 .1 290s5.5-23.7 16.1-29.8l448-256c10.7-6.1 23.9-5.5 34 1.4z" />
            </svg>
            <span>Send Message</span>
          </button>
        </form>
      </section>
    </Layout>
  )
}
export default ContactPage
export const pageQuery = graphql`
  query ContactPageQuery{
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
